app.service('stackedModal', function () {

	let stack = [];
	init();

	function push(modalInstance) {
		stack.push(modalInstance);
		handleCloseEvent(modalInstance);
	}

	function replaceLastModal(modalInstance) {
		stack.length && stack.pop();
		push(modalInstance)
	}

	function handleCloseEvent(modalInstance) {
		modalInstance.$modal.one('closed', () => {
			if (!stack.length) return;
			stack.pop();
			if (stack.length) {
				stack[stack.length - 1].open();
			}
		});
	}

	function init() {
		$(document).on('closed', '.remodal', function () {
			const isAnyModalOpen = stack.find(modal => modal.getState() !== "closed");

			if (!isAnyModalOpen) {
				stack.forEach(modal => modal.destroy());
				stack = [];
			}
		});
	}

	function clearStack() {
		stack = [];
	}

	return {
		clearStack,
		push,
		replaceLastModal
	};
});
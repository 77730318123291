app.service('operator',function(callback, html, loading, cookie, account, modal, front, sticky, operatorVouchers, operatorProductStocks, operatorPluCompare, localStorage, warrantyQuestionnaire, pageConfig, sessionStorage){
	var $self=false;
	var orderPath=false;
	var cssClass='operator-logged';
	var loggedCustomer, customerLoggedName;

	var $modal, $inactiveTimeCounter, timer;
	var inactiveTimeCount = 30;

	function setCount(){
		if($inactiveTimeCounter.length){
			timer=setInterval(function(){
				inactiveTimeCount--;
				if(inactiveTimeCount<1){
					$modal.close();
					clearInterval(timer);
					logoutCustomer();
				}else{
					var text='';
					var second=inactiveTimeCount%60;
					text+=second+' sek.';
					$inactiveTimeCounter.text(text);
				}
			},1000);
		}
	}

	function activeClientModalInit(){
		var isOutFromOrderPath = (localStorage.getItem('outFromOrderPath') == 'true');
		localStorage.setItem('outFromOrderPath',orderPath);
		if(!orderPath && isOutFromOrderPath) {
			$modal = modal.open('active-client',{
				closeOnConfirm: false,
				closeOnCancel: false,
				closeOnEscape: false,
				closeOnOutsideClick: false
			});
			$inactiveTimeCounter = $('#modal-active-client #active-client-counter');
			setCount();
		}
	}

	function showSuccess(){
		if(window.cart){ // usuniety warunek location.protocol.indexOf('https')>-1 ||, poniewaz blokowal poprawne ustawianie order path na QA
			orderPath=true;
		}
		setEvents();					//do ewentualnego przeniesienia
		html.add(cssClass);
		html.remove('operator-reserve-bar');
		operatorVouchers.init();
		operatorProductStocks.init();
		if(front.euro){
			operatorPluCompare.init();
		}

		customerLogged = pageConfig.getData('operator').frontUserLogged;
		customerLoggedName = pageConfig.getData('operator').frontUserLoggedName;
		if(customerLogged){
			if(localStorage.getItem('customer') && localStorage.getItem('customer') !='(nowy klient)' && customerLoggedName != localStorage.getItem('customer')) {
				warrantyQuestionnaire.clearWarrantyQuestionnaire();
			}
			localStorage.setItem('customer', customerLoggedName);
			localStorage.setItem('isLoggedCustomer', true);
		}else{
			if(localStorage.getItem('isLoggedCustomer') == 'true' && localStorage.getItem('customer') !='(nowy klient)'){
				warrantyQuestionnaire.clearWarrantyQuestionnaire();
				localStorage.setItem('isLoggedCustomer', false);
			}
			localStorage.removeItem('customer');
		}
		activeClientModalInit();
		warrantyQuestionnaire.init();
	}

	function show() {
		sessionStorage.setItem('operator-logged','true');
		$('.score').removeClass('hide'); // pokazujemy punkty przy produkcie
			$self=$('#top-bar');
		if($('#top-bar > div').length){
			showSuccess();
		}else{
			$.ajax({
				url : '/front-operator-bar-nobody.ltr',
				success : function(response) {
					$self.html(response);
					showSuccess();
				}
			});
		}
	}

	function setEvents(){
		$('.js-logout-operator', $self).click(function(){
			logoutOperator();
		});
		$self.find('.js-logout-customer').click(function(){
			logoutCustomer();
		});
		$('.js-clear-cart', $self).click(function(){
			warrantyQuestionnaire.clearWarrantyQuestionnaire();
			clearCart();
		});
		$('body').on('click','.js-active-client-continue', function() {
			clearInterval(timer);
			$modal.close();
		});
		$('body').on('click','.js-active-client-logout', function() {
			logoutCustomer();
			$modal.close();
		});
	}
	function logoutCustomer() {
		localStorage.removeItem('rememberShops');//usun zapamietane sklepy
		warrantyQuestionnaire.clearWarrantyQuestionnaire();//usun zapamietane ankiety
		$('#operator-logged-customer').remove();
		$('#operator-not-logged-customer').removeClass('hide');
		loading.start();
		$.ajax({
			url:'/front-operator-bar-logout.ltr',
			type:'POST',
			complete: function(){
				if(orderPath){
					loading.start();
					document.location.href='/cart.bhtml';
				}else{
					loading.stop();
					callback.run('logoutCustomerCallback');
				}
			}
		});
	}

	function clearCart(){

		loading.start();
		UA.interaction('Tryb Operatorski','Belka', 'Wyczyść koszyk');

		$.ajax({
			url: '/clear-cart.ltr',
			dataType: 'json',
			async: false,
			complete: function() {
				location.reload();
			}
		});
	}
	function logoutOperator(a) {
		sessionStorage.removeItem('operator-logged');
		$self.remove();
		html.remove(cssClass);
		sticky.rebuild('topBar');
		if(orderPath){
			loading.start();
			document.location.href='/konto-wyloguj.bhtml';
		}
		else{
			$.ajax({
				url:'/konto-wyloguj.bhtml',
				type:'GET'
			});
			callback.run('logoutOperatorCallback');
		}
		warrantyQuestionnaire.clearWarrantyQuestionnaire();
	}
	if(sessionStorage.getItem('operator-logged')==='true'){
		html.add('operator-reserve-bar');
	}
	function init(data) {
		html.remove('operator-not-logged operator-logged');
		if ((data && data.frontOperatorLogged === true) || $('#top-bar .operator-bar').length) {
			show();
		}else{
			sessionStorage.removeItem('operator-logged');
			html.remove('operator-reserve-bar');
			html.add('operator-not-logged');
		}
	}
	function initOnLoad(){
		if(front.katalog){
			operatorVouchers.init();
			warrantyQuestionnaire.init();
		}else{
			account.onLoad(function(data){
				init(data);
			});
		}
	}
	return {
		init: initOnLoad
	};
});

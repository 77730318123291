app.service('productsListView', function (modal, $global, html, _, lazyLoad, filterBox, message, autocompleteInfo, productPromotionsListModal, promotionListingView, searchResultCollector, nodeTree, ajaxPage, loading, log, productsRecommendation, searchBox, productsListConfig, callback, cookie, modalDelivery, searchRelatedKeywordsCarousel) {
	var config, hash;
	var $products, $goTopList;
	var $self;

	function getProductListInfo(){
		return {
			lastKeyword: config.keyword,
			searchCount: config.count,
			list: config.list,
			products: config.products
		};
	}
	function setEvents(){
		$self.on('click', '.js-more-promotions', function handleMorePromotionsClick () {
			let $product = $(this).closest('.product-box, .product-tile');
			productPromotionsListModal.show($product.data('product-id'), $(this).data('outlet-category-id'));
		});

		$('.attribute-name a', $products).click(function(){
			UA.pageInteraction('Słownik', 'Cecha - '+$.trim($(this).text())+' - '+config.category);
		});
		$('.attribute-value a', $products).click(function(){
			UA.pageInteraction('Słownik', 'Wartość  - '+$.trim($(this).text())+' - '+config.category);
		});
		$goTopList.click(function(){
			$.scrollTo($('#breadcrumb'),300);
		});
		var $div=$('#accessory-searcher');
		if($div.length){
			accessorySearcher(config.accessorySearcherId, config.searcherProductModel);
		}
		$('.stars-rating a:not(.rating-count)', $products).click(function(){
			UA.pageInteraction('Opinie', 'Gwiazdki nad foto');
		});
		$('.stars-rating a.rating-count', $products).click(function(){
			UA.pageInteraction('Opinie', 'Gwiazdki nad foto - Tekst');
		});
		$('.js-save-keyword',$products).click(function(){
			if(config.keyword){
				searchBox.saveKeyword(config.keyword);
			}
		});
		$('a.js-more-opinion',$products).click(function(){
			$(this).closest('.text-column').removeClass('text-column-short');
		});
		$('a.js-less-opinion',$products).click(function(){
			$(this).closest('.text-column').addClass('text-column-short');
		});
		$('.paging a', $self).click(function(event){
			ajaxPage.load({
				url: $(this).attr('href'),
				beforeLoad:function(){
					$.scrollTo($('#breadcrumb'));
				}
			});
			event.preventDefault();
		});
		tilesInit();
		$('.js-products-view-list').click(function(){
			setView('list');
		});
		$('.js-products-view-tiles').click(function(){
			setView('tiles');
		});
		_.onScroll(scrollY => handleScroll(scrollY));

		$('body').on('click', '.js-same-day-shops-notice', function() {
			handleHomeDeliveryClick($(this).data('id'));
		});
	}
	function handleScroll(scrollY){
		if($products.offset().top-scrollY>100){
			$goTopList.addClass('hide');
		}else{
			$goTopList.removeClass('hide');
		}
	}
	function handleHomeDeliveryClick(id) {
		modalDelivery.showModal({
			productId: id
		});
		UA.pageInteraction('CTA', 'Sprawdź termin dostawy');
	}
	function setView(view){
		if(view){
			html.remove('view-list');
			html.remove('view-tiles');
			html.add('view-'+view);
			cookie.set(getViewCookie(),view);
			if(view=='tiles' && config){
				tilesInit();
				lazyLoad.update();
			}
		}
	}
	function getViewCookie(){
		return 'view-'+document.location.pathname.split('.bhtml')[0].split(",")[0].substr(1);
	}
	function countItemsInRow () {
		return $('.page').width() === 1200 ? 4 : 3;
	}

	function getMaxHeight ($items) {
		var maxHeight = 0;

		$items.each(function () {
			var currentItemHeight = $(this).height();

			if (currentItemHeight > maxHeight) {
				maxHeight = currentItemHeight;
			}
		});

		return maxHeight;
	}

	function setEqualHeight ($items, height) {
		$items.height(height);
	}

	function setMinHeight ($items, minHeight, callback) {
		$items.each(function () {
			$(this).css('min-height', minHeight);
			callback($(this));
		});
	}

	function getItemsInRow ($tiles, rowNumber, itemsInRowNumber) {
		return $tiles.slice( rowNumber * itemsInRowNumber, (rowNumber + 1) * itemsInRowNumber );
	}

	function countRows (productsNumber, itemsInRowNumber) {
		return Math.ceil(productsNumber / itemsInRowNumber);
	}

	function addShadowIfNeeded ($description) {
		var heightFromData = $description.data('height');
		var attributesHeight = $description.find('.product-attributes').height();

		if (attributesHeight > heightFromData) {
			$description.addClass('product-desc-long');
		}
	}

	function tilesInit () {
		var $tiles = $('.product-tile:visible', $self);
		var productsNumber = $tiles.length;
		var itemsInRowNumber = countItemsInRow();

		if (productsNumber) {
			var rowsNumber = countRows(productsNumber, itemsInRowNumber);

			for (var i = 0; i < rowsNumber; i++) { // for each row
				var $itemsInRow = getItemsInRow($tiles, i, itemsInRowNumber);

				if ($itemsInRow.length) {
					var tallestItemHeight = getMaxHeight($itemsInRow);
					setEqualHeight($itemsInRow, tallestItemHeight);

					var $descriptions = $itemsInRow.find('.product-desc');
					var tallestDescriptionHeight = getMaxHeight($descriptions);
					setMinHeight($descriptions, tallestDescriptionHeight, addShadowIfNeeded);
				}
			};
		}
	}
	function suggestionTry(){
		log.event({
			id: "search_did_you_mean",
			f03: document.location.pathname + document.location.search + document.location.hash,
			f04: document.referrer,
			f05: config.suggestionTry.initialKeyword,
			f06: config.suggestionTry.suggestionKeyword,
			f07: config.suggestionTry.count,
			f08: config.suggestionTry.category==""?"wszystkie":config.suggestionTry.category
		});
		UA.interaction("Site Search", "Sugestia wyszukania", config.suggestionTry.initialKeyword+' : '+config.suggestionTry.suggestionKeyword);
	}
	function setElements() {
		$self=$('#leaf');
		$products=$('#products',$self);
		$goTopList=$('.js-go-top-list', $self);
	}
	function loadListingMessage(){
		var hash=document.location.hash;
		if (hash.match(/^#fromEmptyListing/) && config.name) {
			message.get({
				code:'redirect_from_empty_listing',
				names: ['category'],
				values: [config.name.replaceAll(',',' ')]
			}).then(function(html){
				$('#from-product').html(html).addClass('showed');
			});
		}
		else if (hash.match(/^#fromProduct-/)) {
			var table = hash.replace(/#fromProduct-/,"").replace(/%7C/gi, "|").split('|').map((item, i) => {
				return decodeURIComponent(item).replace(/<[^>]*>/gi, "");
			});
			var hashQuery={
				product:table[0]
			};

			message.get({
				code:'redirect_from_inactive_or_archival_product',
				names: ['product_name','products_group'],
				values: [hashQuery.product, config.name]
			}).then(function(html){
				$('#from-product').html(html).addClass('showed');
			});
		}
		else if (hash.match(/^#fromPartnerSubscription-/)) {
			var table = hash.replace(/#fromPartnerSubscription-/,"").replace(/%7C/gi, "|").split('|').map((item, i) => {
				return decodeURIComponent(item).replace(/<[^>]*>/gi, "");
			});
			var hashQuery={
				product:table[0]
			};

			message.get({
				code:'redirect_from_partner_subscription',
				names: ['product_name','products_group'],
				values: [hashQuery.product, config.name]
			}).then(function(html){
				$('#from-product').html(html).addClass('showed');
			});
		}
	}
	function init() {
		config = productsListConfig.getData();
		loadListingMessage();
		autocompleteInfo.logResult('listing', config);
		searchResultCollector.init('list',{
			keyword	: config.keyword,
			nodeId	: config.nodeId,
			searchType: '',
			resultNumber : config.count
		});
		log.visit({
			nodeName:config.nodeId,
			keyword:config.keyword || undefined
		}, function(){
			if(config.suggestionTry !== false){
				suggestionTry();
			}
		});
		setElements();
		setEvents();
		$('#outlet-slider').productSlider({
			maxElementShift: 2
		});
		filterBox.init();
		nodeTree.init();
		productsRecommendation.init();
		callback.run('getProductListInfo', getProductListInfo());
		promotionListingView.init();

		if (config.keyword.length) { // jeżeli jesteśmy na stronie wyników wyszukiwania
			searchRelatedKeywordsCarousel.init();
		}
	}


	if(cookie.isEmpty('atrUser')){
		setView(cookie.get(getViewCookie()));
	}else{
		setView('list');
	}
	return {
		init
	};
});

/**
 * Moduł podpowiedzi dla wyszkukiwarki.
 * @type {module}
 */
app.service('autocomplete', function (searchHistory, search, loading, cookie, front, pageConfig, autocompleteInfo, logData, developSearchModeTest) {
	var $input;
	var $results;
	var $searchBox;
	var $sidebarResults;
	var $container;
	var $resultsItem;
	var $activeCategory;
	var $lastAjax=null;
	var $loadProductsAjax=null;
	var searchTimeout = false;
	var inputTimeout = false;
	var impressionsTimeout = false;
	var savedProducts = [];
	var testAB;

	var keyCode = {
		up: 38,
		down: 40,
		enter: 13,
		esc: 27
	};
	var options = {
		minInputLength: 3,
		maxInputLength: 64,
		charactersInQueryLimit: 64,
		historyLimit: 2,
		resultsLimit: 5,
		ajaxUrl: '/searchAutocomplete.ltr',
		loadProductsTimeout: 250,
		impressionsTimeout: 1000,
		inputTimeout: 200
	};
	var lastResponse = {};
	var summaryResults = {};
	var linkName = '';

	function copyToInput(el) {
		$input.val(el);
	}
	function nav(e) {	// obsługa klawiatury
		var activeItem = $('#ac-results .ac-active');
		var item = $('#ac-results .ac-item');

		if(activeItem.length) {
			activeItem.removeClass("ac-active");
			activeItem = (e.type === 'ac:up') ? activeItem.prev(".ac-item") : activeItem.next(".ac-item");
			activeItem.addClass("ac-active");
			copyToInput($(".ac-text", activeItem).text());
		} else {
			activeItem = (e.type === 'ac:up') ? item.last(".ac-item") : item.first(".ac-item");
			activeItem.addClass("ac-active");
			copyToInput($(".ac-text", activeItem).text());
		}

		activeItem.trigger('mouseenter');
		setActiveCategory(activeItem);
	}
	function setActiveCategory(item) {
		$("#search-category").val($(item).attr("data-category"));
	}
	function onKeyUp(e) {
		var inp = String.fromCharCode(e.keyCode);
		if(inp != ' ' && $.inArray(e.keyCode, [17,39,37]) == -1) {
			keyTest(e.keyCode);
		}
	}
	function onChoose() {
		var obj = {};
		var active = $("#ac-results .ac-active");
		var text = active.data("text") || $input.val();
		var url = active.data("url") || "";
		var category = active.data("category") || "";
		obj[text] = { link: url };

		if(text.length > 1){
			searchHistory.add(obj);
		}
		if(!!url) {
			loading.start();
			document.location.href=url;
		} else {
			search.search(text);
		}
		$input.trigger("blur");
	}
	function getActiveCategory() {
		return $.trim($("#search-category").val());
	}
	function onEsc() {

	}
	function onOpen(e) {
		$searchBox.addClass("ac-open");
		$activeCategory = getActiveCategory();
		var q = e.target.value;
		if(q.length < 3) {
			if((front.katalog && q.length>1) || !front.katalog){
				$input.trigger('ac:inputShort');
			} else if(front.katalog && q.length<=1) {
				$searchBox.addClass('empty');
			}
		} else {
			$input.trigger('ac:inputLong');
			$searchBox.addClass('searching');
		}
	}
	function onInputShort() {
		var q = $input.val();
		lastResponse = {};
		summaryResults = $.extend({}, find(q));
		render();
	}
	function startsWith(str, str2) {
		return str.indexOf(str2) === 0;
	}
	function filterResponse(item) {
		var exist = false;
		$.each(lastResponse, function(name, group){
			$.each(group, function(key, obj) {
				if(this.name === item) {
					exist = true;
				}
			});
		});
		return exist;
	}
	function find(q) {
		var his = searchHistory.get();
		var suggestions = {saved:[]};
		$.each(his, function(i, obj) {
			if(Object.keys(obj).length === 0){
				return false;
			}
			var keyword = Object.keys(obj)[0];
			var keywordLower = keyword.toLowerCase();
			if(startsWith(keywordLower, q.toLowerCase()) && !filterResponse(keywordLower.toLowerCase())) {
				suggestions.saved.push({name: keyword, url: obj[keyword].link});
			}
		});
		suggestions.saved = suggestions.saved.slice(0, options.historyLimit);
		if (suggestions.saved.length) {
			autocompleteInfo.setData({
				type: 'history',
				historyLength: suggestions.saved.length
			});
		}
		return suggestions;
	}
	function onInputLong(e) {
		var q = $input.val();
		var newLinkName = getLinkName();

		if($.isEmptyObject(lastResponse) || linkName != newLinkName) {
			onQuery(e);
			return;
		}
		summaryResults = $.extend({}, find(q), lastResponse);
		render();
	}

	function getPromotionSuggestion ({ name, link, image }) {
		return `
			<div class="ac-item ac-gorup-promotion" data-url="${link}">
				<img src="${image}" title="${name}" /> <div class="promotion-text">${name}</div>
			</div>
		`;
	}

 	function render() {
		var $tempResults = $results.detach();
		var n = 1;
		var preparedResults = 1;
		var savedAutocomplete = 1;
		clearResults();
		function prepareResults(groups) {
	 		$.each(groups, function(name, group){
				if (name === 'promotionSuggestion' && group) {
					var promotionSuggestion = getPromotionSuggestion(groups.promotionSuggestion);
					$tempResults.find('.ac-group-categorySuggestions').last().after(promotionSuggestion);
					preparedResults++;
				} else {
					$.each(group, function(j, obj){
						preparedResults++;
						if(n <= options.resultsLimit) {
							if (name === 'saved') {
								savedAutocomplete++;
							}
							$tempResults.append(prepareItem(obj, name, j+1));
							n++;
						}
					});
				}
			});
			if(preparedResults - savedAutocomplete === 0) {
				$searchBox.removeClass('searching empty');
				if (savedAutocomplete === 1) {
					$searchBox.addClass('empty');
				}
			} else {
				$searchBox.removeClass('empty');
				if(preparedResults > savedAutocomplete) {
					$searchBox.addClass('searching');
				}
			}
	 		return $tempResults;
		 }
		$container.prepend(prepareResults(summaryResults));
 	}
	function onQuery(e) {
		var q = e.target.value.replace(/[^ąćęłńóśźżĄĆĘŁŃÓŚŹŻ\w\s]/gi, ' ').trim();
		q = q.slice(0, options.charactersInQueryLimit);

		clearTimeout(inputTimeout);
		if(q.length < 3) {
			onInputShort();
			return;
		} else {
			inputTimeout = setTimeout(function(){
				searching(e, q);
			}, options.inputTimeout);
		}
	}
	function searching(e, q) {
		linkName = getLinkName();
		if($lastAjax!==null){
			$lastAjax.abort();
			$lastAjax=null;
		}
		var data = {
			keyword: q,
			linkName: linkName || '0'
		}
		if (front.katalog) {
			data.wcShopCode = pageConfig.getData('wc').shopCode;
		}
		$lastAjax=$.ajax({
			url: options.ajaxUrl,
			data: $.extend(data, {developSearchMode: developSearchModeTest.isEnabled()}),
			success: function(response) {
				lastResponse = response;
				onInputLong(e);
				var categorySuggestionsLength = lastResponse.categorySuggestions && lastResponse.categorySuggestions.length ? lastResponse.categorySuggestions && lastResponse.categorySuggestions.length : 0;
				var suggestionsLength = lastResponse.suggestions && lastResponse.suggestions.length ? lastResponse.suggestions.length : 0
				if (categorySuggestionsLength){
					let res = lastResponse.categorySuggestions[0];
					loadProducts(res, true);
					autocompleteInfo.setData({
						count: categorySuggestionsLength + suggestionsLength,
						categoryLength: categorySuggestionsLength,
						suggestionsLength: suggestionsLength,
						category:{
							type: 'category',
							position: 1,
							text: res.name,
							fullText: res.categorySufix ? res.name+res.categorySufix : res.name,
							category: res.categoryName
						}
					});

				} else if (suggestionsLength){
					let res = lastResponse.suggestions[0];
					loadProducts(res);
					autocompleteInfo.setData({
						count: categorySuggestionsLength + suggestionsLength,
						categoryLength: categorySuggestionsLength,
						suggestionsLength: suggestionsLength,
						category:{
							type: 'suggestions',
							position: 1,
							text: res.name,
							fullText: res.categorySufix ? res.name+res.categorySufix : res.name,
							category: res.categoryName
						}
					});
				}
			},
			error: function() {
				lastResponse = {};
			}
		});
	}
	function getLinkName() {
		return $("#search-category").val();
	}
	function onForget(e) {
		e.stopPropagation();
		var text = $(this).prev().text();
		var $parent = $(this).parent();
		searchHistory.remove(text);
		$parent.remove();
	}
	function onCloseAC(e) {
		if(!$(e.target).closest('.ac-container').length) {
			if($($searchBox).hasClass("ac-open")) {
				$searchBox.removeClass("ac-open");
				clearResults();
				$("#search-category").val($activeCategory);
			}
		}
	}
	function clearResults() {
		$results.html('');
	}
	function prepareLink(link){
		let testAB = cookie.get('wyszukiwarkaTestAB');
		if(link){
			if(testAB === 'G'){
				link = link.replace('/search.bhtml', '/search2.bhtml');
			} else if(testAB === 'P'){
				link = link.replace('/search.bhtml', '/search3.bhtml');
			}
			link += link.indexOf('?') === -1 ? '?' : '&';
			link += 'optimized_search=1';
			return link;
		}
		return false;
	}
	function prepareItem(obj, groupname, position) {
		var val = $input.val();
		val = val.replace(/[^ąćęłńóśźżĄĆĘŁŃÓŚŹŻ\w\s]/gi, ' ').trim();
		var text = obj.name;
		if(val) text = obj.name.replace(new RegExp('(' + val + ')', 'gi'), "<b>$1</b>");
		var group = '';
		if(groupname == "categorySuggestions") {
			group = 'category';
		} else if(groupname == "suggestions") {
			group = 'suggestion';
		} else if(groupname == "saved") {
			group = 'history';
		}
		var $item = $('<div/>', {
 			'class': 'ac-item ac-group-' + groupname,
 			'data-text': obj.name,
			'data-full-text': obj.categorySufix ? obj.name+' '+obj.categorySufix : obj.name,
			'data-position': position,
 			'data-url': prepareLink(obj.link) || prepareLink(obj.url) || "",
 			'data-category': obj.categoryName || "0",
			'data-group': group
		 });

 		var textEl = $("<span/>", {'class':"ac-text"}).html(text);
 		var textCat = $("<i/>", {text: obj.categorySufix});
		$item.append(textEl);
		$item.on('mouseenter', function() {
			loadProducts(obj);
		});
 		if(obj.categorySufix) $item.append(textCat);
		if(groupname === 'saved') {
			$item.append($("<span/>", {
				'class': 'forget',
				text: 'usuń'
			}));
		}
 		return $item;
 	}
	function highlightOn () {
		$("#ac-results .ac-item").removeClass('ac-active').not(this);
		var $this = $(this);
		$this.addClass('ac-active');
		autocompleteInfo.setData({
			category:{
				type: $this.data('group'),
				position: $this.data('position'),
				text: $this.data('text'),
				fullText: $this.data('fullText'),
				category: $this.data('category')
			}
		});
	}

	function loadProducts(autocompleteItem, force = false) {
		function load(){
			if(data.suggestion && data.suggestion.length){
				$sidebarResults.addClass('loading');
				if($loadProductsAjax !== null){
					$loadProductsAjax.abort();
				}

				$loadProductsAjax = $.ajax({
					url: '/searchAutocompleteProducts.ltr',
					method: 'GET',
					data: $.extend(data, {developSearchMode: developSearchModeTest.isEnabled()}),
					success: function (result) {
						generateTemplate(result.products, autocompleteItem.link, text);
						savedProducts.push({keyword: data.suggestion, result, link: autocompleteItem.link, text: text});
					},
					error:function(){
						//
					},
					complete:function(){
						$sidebarResults.removeClass('loading');
					}
				});
			}
		}
		clearTimeout(searchTimeout);
		autocompleteItem && autocompleteItem.name ? null : autocompleteItem = { name: '', linkName: ''};
		var text = autocompleteItem.name;
		if(autocompleteItem.categorySufix){
			text+=' '+autocompleteItem.categorySufix;
		}
		var data = {
			suggestion: autocompleteItem.name,
			linkName: autocompleteItem.linkName || undefined,
			userId: logData.customerId(),
			showRecommendationsFromAWSonMainPage: logData.showRecommendationsFromAWSonMainPage(),
		}
		if (front.katalog) {
			data.wcShopCode = pageConfig.getData('wc').shopCode;
		}

		const foundProducts = savedProducts.find(x => x.keyword === autocompleteItem.name);
		if(foundProducts) {
			generateTemplate(foundProducts.result.products, foundProducts.link, foundProducts.text);
		} else {
			searchTimeout = setTimeout(function() {
				load()
			},force ? 0 : options.loadProductsTimeout);
		}
	}

	function setListeners() {
		$(document).on("click", onCloseAC);
		$input.on('ac:enter', function(){
			autocompleteInfo.logData({
				type: 'enter'
			});
			onChoose();
		});
		$input.on('ac:esc', onEsc);
		$input.on('keyup', onKeyUp);
		if(testAB !== 'P'){
			$input.on('ac:query', onQuery);
			$input.on('focus', onOpen);
			$input.on('ac:up ac:down', nav);
			$input.on('ac:inputShort', onInputShort);
			$input.on('ac:inputLong', onInputLong);
		}
		$results.on("mouseenter mouseleave",".ac-item", highlightOn);
		$results.on("click", '.forget', onForget)
			.on("click",".ac-item", function(){			// wyniki w lewej kolumnie
				var $this = $(this);
				autocompleteInfo.logData({
					type: $this.data('group'),
					category:{
						position: $this.data('position'),
						text: $this.data('text'),
						fullText: $this.data('full-text'),
						id: $this.data('category')
					}
				});
				onChoose();
			});
		$('#sidebar-results').on("click",".search-products-item", function(){			// produkty w prawej kolumnie
			var $this = $(this);
			autocompleteInfo.logData({
				type: 'product',
				count: visibleProducts.length,
				product: visibleProducts.filter(function(item){return item.id == $this.data('id')})[0]
			});
		}).on("click",".products-link", function(){			// więcej produktów pod produktami
			autocompleteInfo.logData({
				type: 'more-products',
				count: visibleProducts.length
			});
		});

	}
	function keyTest(k) {
		switch (k) {
			case keyCode.up:
				$input.trigger('ac:up');
				break;
			case keyCode.down:
				$input.trigger('ac:down');
				break;
			case keyCode.enter:
				$input.trigger('ac:enter');
				break;
			case keyCode.esc:
				$input.trigger('ac:esc');
				break;
			default:
				$input.trigger('ac:query');
		}
	}

	var visibleProducts = [];

	function generateTemplate(products, link, suggestionText){
		var result = '';
		var keyword = $input.val();
		visibleProducts = [];
		
		products.forEach((product, i) => {
			let link = product.cardDefaultUrl ? prepareLink(product.cardDefaultUrl) : '#';
			result += `<a href="${link}" class="search-products-item" data-id="${product.id}">
				<div class="product-photo">
					${product.photo ? `<img src="${product.photo}" alt="${product.name}"/>` : ''}
				</div>
				<div class="product-content">
					<div class="title-box">
						<div class="product-name">
							${product.name ? product.name : ''}
						</div>
						<div class="product-price">
							<div class="price-normal">
								${product.price ? product.price + "&nbsp;zł" : ""}
							</div>
						</div>
					</div>
					<div class="product-category">
						${product.groupName ? product.groupName : ""}
					</div>
					<div class="stars-rating">
						${product.cardDefaultUrl ? `
							<div>
								${product.grade ? `<span style="width:${20*product.grade}%;"></span>` : ''}
							</div>
							<div class="rating-count">
								${product.quantity ? `<em>(${product.quantity})</em>` : ""}</em>
							</div>
						` : ''}
					</div>
				</div>
			</a>`;
			if(suggestionText && keyword.length > 2){
				visibleProducts.push({
					id: product.id,
					plu: product.productInfoCode,
					list: 'Wyszukiwarka/Polecane produkty',
					position: (i+1),
					price: product.productInfoPrice,
					category: product.productInfoGroupName,
					name: product.productInfoName,
					brand: product.productInfoBrandName,
					variant: '',
					status: product.productInfoAvailabilityStatusCode,
					dimension56: keyword,
					dimension59: suggestionText
				});
			}
		});
		if(link){
			result += `<a class="products-link" href="${prepareLink(link)}?optimized_search=1">Więcej produktów</a>`;
		}
		$sidebarResults.html(result);
		if(suggestionText && keyword.length > 2 && products.length && visibleProducts.length){
			clearTimeout(impressionsTimeout);
			impressionsTimeout = setTimeout(function(){
				logImpressions();
			}, options.impressionsTimeout);
		}
	}
	var loggedImpressions = [];
	function logImpressions(){
		var result = [];
		if(visibleProducts && visibleProducts.length){
			visibleProducts.forEach((product, i) => {
				var isLogged = loggedImpressions.filter(function (item,j){
					return item.id == product.id && item.dimension56 == product.dimension56 && item.dimension59 == product.dimension59;
				});
				if(isLogged.length == 0){
					result.push(product);
					loggedImpressions.push(product);
				}
			});
			if(result.length){
				UA.push({
					'ecommerce': {
						'impressions': result
					}
				});
				UA.push({ 'event': 'productListView' });
			}
		}
	}

	function init() {
		testAB = cookie.get('wyszukiwarkaTestAB');
		$input = $("#keyword");
		$results = $('#ac-results');
		$searchBox = $('#search-box');
		$sidebarResults = $('#sidebar-results');
		$container = $('#search-box-result');
		$resultsItem = $("#ac-results .ac-item");
		$activeCategory = getActiveCategory();
		options.historyLimit = front.getParam('autocomplete.historySuggestions');
		options.resultsLimit = front.getParam('autocomplete.maxSuggestions');
		options.charactersInQueryLimit = front.getParam('autocomplete.charactersInQueryLimit');
		options.loadProductsTimeout = front.getParam('autocomplete.loadProductsTimeout');
		options.impressionsTimeout = front.getParam('autocomplete.impressionsTimeout');
		options.inputTimeout = front.getParam('autocomplete.inputTimeout');
		linkName = getLinkName();
		$sidebarResults.addClass('loading');
		if(testAB !== 'P'){
			generateTemplate([{},{},{},{},{}], false, false);
		}
		setListeners();
	}

	return {
		init: init
	};
});

/**
 * Moduł obsługujący nowe główne menu strukturalnie współdzielone z headless
 * @type {module}
 */
app.service('headlessMenu',function(html, sticky, front, lazyLoad, loading) {
	var isInit = false, isMenuOpen = false, isAccountArea = false;
	var $self, $navToggle, $navToggleSticky, $menuCategories, $items, $uspItems, $accountAreaMenuToggle, $accountAreaMenu;

	var activeMenuCategory=null;

	// cache elementow DOM
	function setElements() {
		$self = $('#headless-main-menu');
		$navToggle = $('#nav-toggle');
		$navToggleSticky = $('#nav-toggle-sticky');
		$menuCategories = $('#categories-menu');
		$items = $menuCategories.find('.nav-category-item');
		$itemsContents = $menuCategories.find('.nav-category');
		$uspItems = $('#usp-menu li', $self);
		$accountAreaMenuToggle = $('#account-area-toggle a', $self);
		isAccountArea = $accountAreaMenuToggle.length ? true : false;
		$accountAreaMenu = $('#account-area-menu', $self);
	}

	function activateCategoryContent(menuItem) {
		const $menuItem = $(menuItem);
		const index = $menuItem.data('index');
		if (index === activeMenuCategory) {
			return;
		}
		html.add('menu-open');
		resetItems();
		$menuItem.addClass('active');

		$itemsContents.filter(`[data-index="${index}"]`).addClass('active');
		activeMenuCategory = index;
		lazyLoad.check();
	}

	function resetItems() {
		$items.removeClass('active');
		$itemsContents.removeClass('active');
		activeMenuCategory = null;
	}

	function openMainMenu() {
		if(isMenuOpen) {
			return;
		}
		if (isAccountArea) {
			closeAccountAreaMenu();
		}
		$navToggle.addClass('active');
		$navToggleSticky.addClass('active');
		$menuCategories.addClass('active');
		isMenuOpen = true;
		html.remove('menu-close-button menu-close-cursor-above');
		html.add('menu-open');
	}
	function closeMainMenu(outsideClick = false) {
		$navToggle.removeClass('active');
		$navToggleSticky.removeClass('active');
		$menuCategories.removeClass('active');
		resetItems();
		isMenuOpen = false;
		!outsideClick ? html.add('menu-close-button') : html.add('menu-close-cursor-above');
		html.remove('menu-open');
	}

	function openAccountAreaMenu() {
		if(isMenuOpen) {
			closeMainMenu();
			isMenuOpen = false;
		}
		$accountAreaMenuToggle.addClass('active');
		$accountAreaMenu.addClass('active');
		html.add('menu-open');
	}
	function closeAccountAreaMenu() {
		$accountAreaMenuToggle.removeClass('active');
		$accountAreaMenu.removeClass('active');
		html.remove('menu-open');
	}

	function toggleCategoryContent(menuItem, force = false) {
		if (force && $(menuItem).data('index') === activeMenuCategory) {
			resetItems();
			activeMenuCategory = false;
		} else {
			activateCategoryContent(menuItem);
		}
	}

	function setEvents(){
		$navToggle.on('click', function() {
			isMenuOpen ? closeMainMenu() : openMainMenu();
		});
		$navToggleSticky.on('click', function() {
			isMenuOpen ? closeMainMenu() : openMainMenu();
		});
		if (isAccountArea) {
			$accountAreaMenuToggle.on('click', function(e) {
				e.preventDefault();
				$accountAreaMenu.hasClass('active') ? closeAccountAreaMenu() : openAccountAreaMenu();
			});
		}

		$items.on('mouseenter', function(){
			toggleCategoryContent(this);
		}).on('mouseleave', function(e) {
			if(!$(e.target).closest($menuCategories).length){
				toggleCategoryContent(this);
			}
		}).on('click', function(e) {
			e.preventDefault();
			toggleCategoryContent(this,true);
		});
		$menuCategories.on('mouseleave', function(){
			toggleCategoryContent(this);
		})

		$('body').on('click', function(e){
			if (isMenuOpen) {  // klikniecia poza menu głównym
				if (!$(e.target).closest($self).length && !$(e.target).closest($navToggleSticky).length) {
					closeMainMenu(true);
				}
			}
			if (isAccountArea && $accountAreaMenu.hasClass('active')) {  // klikniecia poza menu strefy klienta
				if (!$(e.target).closest($accountAreaMenu).length && !$(e.target).closest($accountAreaMenuToggle).length) {
					closeAccountAreaMenu();
				}
			}
		});

	}

	function init() {
		if(!isInit){
			setElements();
			if($self.length){
				setEvents();
			}
		}
		isInit=false;
	}
	return {
		init
	};
});

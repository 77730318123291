/* moduł zawierający globalne nakładki na AJAX'y */
app.service('ajaxOverwrite', function(modal, console, front, cookie, loading, message) {

	var customerAccountCaptchaRequiredUrls = [
		"/customer-esd-codes.ltr",
		"/customer-order-cancel.ltr",
		"/customer-order-cancel-reason.ltr",
		"/customer-remove-delivery-address.ltr",
		"/customer-set-default-delivery-address.ltr",
		"/customer-set-default-receiver-data.ltr",
		"/customer-erp-order-print.ltr",
		"/customer-set-default-invoice-address.ltr",
		"/customer-gift-card-show.ltr",
		"/customer-order-email.ltr",
		"/customer-delivery-address-edit.ltr",
		"/customer-receiver-data-edit.ltr",
		"/customer-invoice-data-edit.ltr",
		"/customer-remove-invoice-address.ltr",
		"/customer-all-complaints.ltr",
		"/customer-order-complaints.ltr",
		"/customer-order-complaint-details.ltr",
		"/customer-reclamation-request.ltr",
		"/customer-return-request.ltr",
		"/customer-remove-receiver-data.ltr",
		"/customer-order-document-download.ltr",
		"/customer-esd-codes-print-view.ltr",
		"/customer-order-delivery-time.ltr",
		"/customer-change-payment-type-additional-sections.ltr",
		"/customer-subscription-service-contract-list.ltr",
		"/customer-subscription-service-contract-close.ltr",
		"/customer-subscription-service-contract-close-reason.ltr",
		"/customer-subscription-service-contract-details.ltr",
		"/customer-subscription-payment-change-card.ltr",
		"/customer-subscription-warranty-payment-list.ltr",
		"/customer-warranty-subscription-close.ltr",
		"/customer-warranty-subscription-close-reason.ltr",
		"/customer-subscription-service-contract-activity.ltr",
		"/customer-subscription-invoice-data-update.ltr"];

	function clearCss() {
		$('#body [style]').removeAttr('style')
		$('link[href*="hotfix"]').remove();
		$("STYLE").remove();
	}
	if (document.location.search.indexOf('ucm-scripts') > -1) {
		$(document).ready(function() {
			clearCss();
		});
	}
	if (document.location.search && document.location.search.indexOf('ucm-scripts') > -1) {
		$.ajaxSetup({
			data: {
				'ucm-scripts': 'off'
			}
		});
		//$('link[type="text/css"]')
		clearCss();
	}
	if (document.location.search && document.location.search.indexOf('ucm-cache') > -1) {
		$.ajaxSetup({
			data: {
				'ucm-cache': 'off'
			}
		});
	}
	var ajaxTime = [];

	$(document).ajaxSend(function(event, request, settings) {
		clearTimeout(ajaxTime[settings.url]);
		ajaxTime[settings.url] = setTimeout(function() {
			app.logEvent({
				id: 'timeout-ajax',
				f03: settings.url.indexOf('?') > -1 ? settings.url.substr(0, settings.url.indexOf('?')) : settings.url,
				f04: front.page(),
				f05: cookie.get('JSESSIONID')
			});
			UA.interaction('Monitoring', 'Timeout ajaxa', document.location.href);
		}, 1000 * 10)
		if (front.katalog && settings.url.indexOf('.ltr') > -1 && settings.url.indexOf('/shopEvent.ltr') == -1) {
			$(document).trigger('ajaxUpdate');
		}
	});
	$(document).ajaxComplete(function(event, request, settings) {
		clearTimeout(ajaxTime[settings.url]);
	});
	$(document).ajaxError(function(event, jqXHR, settings, thrownError) {
		if (thrownError !== "abort" && settings.url.indexOf('/shopEvent.ltr') == -1 && (settings.url.indexOf('.ltr') > -1 || settings.url.indexOf('.bhtml') > -1)) {
			app.logEvent({
				id: 'error-ajax',
				f03: settings.url.indexOf('?') > -1 ? settings.url.substr(0, settings.url.indexOf('?')) : settings.url,
				f04: front.page(),
				f05: cookie.get('JSESSIONID')
			});
			UA.interaction('Monitoring', 'Błąd ajaxa', document.location.href);
		}
		console.log('Błąd ajaxa: ' + settings.url);

		var header = jqXHR.getResponseHeader('CSRF_ERROR');
		var statusCode = jqXHR.status;
		var backend = jqXHR.getResponseHeader('x-backend');

		if (statusCode === 403 && customerAccountCaptchaRequiredUrls.includes(settings.url.split("?")[0])) {
			function go() { 
				modal.close();
				loading.redirect('/klient-ochrona-konta.bhtml');
			}
			message.alert('message-account-403-ltr', function () {
				go();
			});
			setTimeout(go, 4000);
		}
		else if (header) {
			if (statusCode === 403) {
				modal.alert("Wystąpił błąd, spróbuj ponownie później.", function() {
					loading.reload();
				});
			} else if (statusCode === 401) {
				modal.alert("Zostałeś wylogowany, zaloguj się ponownie.", function() {
					loading.reload();
				});
			}
		} else if ((statusCode === 403 || statusCode === 401) && settings.url.indexOf('customer_j_spring_security_check.ltr') == -1 && settings.url.indexOf('/shopEvent.ltr') == -1) {
			if (backend) {
				$('#fancybox-overlay').remove();
				$('#fancybox-wrap').remove();
				modal.alert("Twoja sesja wygasła. Prosimy zalogować się ponownie.", function () {
					loading.reload();
				});
			} else { 
				console.info('Zablokowane żądanie');
			}
		}
	});
	var token;
	var header;
	$.ajaxSetup({
		beforeSend: function(request) {
			var $meta;
			if(!token && !header){
				$meta = $("meta[name='_csrf']");
				if($meta.length){
					token = $meta.attr("content");
					$meta = $("meta[name='_csrf_header']");
					header = $meta.attr("content");
				}
			}
			if (token && header) {
				request.setRequestHeader(header, token);
			} else {
				var $input = $("input[name='_csrf']:last");
				if ($input.length) {
					request.setRequestHeader('X-CSRF-TOKEN', $input.val());
				}
			}
		}
	});
});

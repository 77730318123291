app.service('_', function() {
	// A (possibly faster) way to get the current timestamp as an integer.
	var now = Date.now || function() {
		return new Date().getTime();
	};
	// Returns a function, that, as long as it continues to be invoked, will not
	// be triggered. The function will be called after it stops being called for
	// N milliseconds. If `immediate` is passed, trigger the function on the
	// leading edge, instead of the trailing.
	var debounce = function(func, wait, immediate) {
		var timeout, args, context, timestamp, result;

		var later = function() {
			var last = now() - timestamp;

			if (last < wait && last >= 0) {
				timeout = setTimeout(later, wait - last);
			} else {
				timeout = null;
				if (!immediate) {
					result = func.apply(context, args);
					if (!timeout) context = args = null;
				}
			}
		};

		return function() {
			context = this;
			args = arguments;
			timestamp = now();
			var callNow = immediate && !timeout;
			if (!timeout) timeout = setTimeout(later, wait);
			if (callNow) {
				result = func.apply(context, args);
				context = args = null;
			}

			return result;
		};
	};

	var onScroll = function(fn) {
		var supportsPassive = false;
		var lastKnownScrollPosition = 0;
		var ticking = false;

		try {
			var opts = Object.defineProperty({}, 'passive', {
				get: function() {
					supportsPassive = true;
				}
			});
			window.addEventListener("test", null, opts);
		} catch (e) {}

		var handleScroll = function(e) {
			lastKnownScrollPosition = window.pageYOffset ;
			if (!ticking) {
				window.requestAnimationFrame(function() {
					fn(lastKnownScrollPosition);
					ticking = false;
				});
			}
			ticking = true;
		};

		window.addEventListener('scroll', handleScroll, supportsPassive ? { passive: true } : false); 
	};

	const escapeDiacritics = function(text) {
		return text.replace(/ą/g, 'a').replace(/Ą/g, 'A')
			.replace(/ć/g, 'c').replace(/Ć/g, 'C')
			.replace(/ę/g, 'e').replace(/Ę/g, 'E')
			.replace(/ł/g, 'l').replace(/Ł/g, 'L')
			.replace(/ń/g, 'n').replace(/Ń/g, 'N')
			.replace(/ó/g, 'o').replace(/Ó/g, 'O')
			.replace(/ś/g, 's').replace(/Ś/g, 'S')
			.replace(/ż/g, 'z').replace(/Ż/g, 'Z')
			.replace(/ź/g, 'z').replace(/Ź/g, 'Z');
	};

	function formatPrice(price) {
		var p = price.toString().split('.');
		var result = p[0];
		if (p[0].length > 3) {
			result = p[0].substr(0, p[0].length - 3) + ' ' + p[0].substr(p[0].length - 3, 3);
		}
		if (p[1]) {
			if (p[1].length == 1) {
				result += ',' + p[1] + '0';
			} else {
				result += ',' + p[1].substr(0, 2);
			}
		}
		return result + ' zł';
	}

	return {
		debounce: debounce,
		onScroll: onScroll,
		escapeDiacritics: escapeDiacritics,
		formatPrice
	};
});

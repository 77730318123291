/**
 * Moduł odpowiedzialny za bloczek użytkownika w nagłówku, udostępnia informacje o użytkowniku. Dopiero po załadowaniu bloczka doczytywany jest tryb operatorski i dolna belka
 * @type {module}
 */


app.service('account',function($global, callback, html, front, loading, localStorage, modal, pageConfig, broker) {
	var $self = false;
	var $showMenu = false;
	var isLoad=false;
	var onLoadCallbacks=[];

	var user = {}; // informacje zwrócone z ajaxa
	var isInit = false;

	function showMessageForFrontUser(message) {
		modal.alert(message);
	}

	function getInfo() {
		if(front.katalog){
			var wc=pageConfig.getData('wc');
			return $.extend(true, {
				wcEmail:wc.email
			}, user);
		}else{
			return $.extend(true, {}, user);
		}
	}
	function userLoggedSuccess(data) {
		user = data;
		$('.js-clipboard-products-count').text(user.customerWatchedProductsCount > 0 ? user.customerWatchedProductsCount: ''); // Aktualizacja liczkika schowka dla KK
		$('#account-menu .clipboard em').text(user.customerWatchedProductsCount); // TODO Stary nagłówek
		html.remove('user-not-logged user-logged');
		if (data.frontUserLogged === true) {
			html.add('user-logged');
			$('.js-user-name').text(data.firstname);
			// $('.js-user-email').text(data.email); //do weryfikacji
		} else {
			html.add('user-not-logged');
		}
		permissionToProcessing = data.permissionToProcessing; // do
		// weryfikacji
		permissionToAccessForCooperatingEntities = data.permissionToAccessForCooperatingEntities; // do
		// weryfikacji
		permissionToElectronicChannel = data.permissionToElectronicChannel; // do
		// weryfikacji

		if (data.showMessageForFrontUser === true) {
			showMessageForFrontUser(data.messageForFrontUser);
		}

		loginsCounter = data.loginsCounter; // do weryfikacji
		urlWhichEnforcesLogin = (typeof data.urlWhichEnforcesLogin !== "undefined") ? data.urlWhichEnforcesLogin
				: ""; // do weryfikacji
		onLoadInit(data);
	}
	function onLoadInit(data){
		isLoad=true;
		callback.run('USER_DATA', $.extend({},data));
		for(var i=0;i<onLoadCallbacks.length;i++){
			var call=onLoadCallbacks[i];
			call($.extend({},data));
		}
	}
	function getUserData() {
		if ($self.length) {
			var params = $.parseQuery(); // do przeniesienia
			//var searchData = getSearchData(); // do przeniesienia

			$.ajax({
				url : '/userData.ltr',
				dataType : 'json',
				cache : false,
				data : {
					from : params.from,
					lastViewedProductId : front.getParam('lastViewedProductId')
				},
				success : function(data, status, xhr) {
					broker.trigger('DATETIME_ONLOAD', [xhr.getResponseHeader('Date')]);
					userLoggedSuccess(data);
				},
				error : function(xhr, err) {
					broker.trigger('DATETIME_ONLOAD', [xhr.getResponseHeader('Date')]);
					onLoadInit(false);
					if (xhr.status == 503) {
						callback.run('get503Page');
					}
				}
			});
		}else{
			onLoadInit(false);
		}
	}

	function setElements(){
		if(localStorage.getItem('watched-products-list-permissions-minimalize')){// warunek do usunięcia po jakimś czasie od wdrozenia zadania
			localStorage.setItem('permissions-minimalize', true);
			localStorage.removeItem('watched-products-list-permissions-minimalize');
		}
		$self = $('#account-box');
		$showMenu = $self.find('.js-show-user-menu');
	}
	function show(){
		html.add('show-account-menu');
	}
	function hide(){
		html.remove('show-account-menu');
	}
	function isShow(){
		return html.has('show-account-menu');
	}

	function setEvents(){
		$showMenu.click(function(event){
			if(isShow()){
				hide();
			}else{
				show();
			}
			event.preventDefault();
		});
		$('body').click(function(event){
			if( !$(event.target).closest('#account-box').length ) {
				hide();
			}
		});
	}
	function onLoad(callback){
		if(isLoad){
			callback();
		}else{
			onLoadCallbacks.push(callback);
		}
	}
	function init() {
		if (!isInit) {
			setElements();
			setEvents();
			if(front.katalog){
				onLoadInit({});
			}else{
				getUserData();
			}
			isInit = true;
		}
	}


	function isFrontUserLogged() {
		var returnValue = false;
		loading.start();
		$.ajax({
			url: '/front-user-logged.ltr',
			dataType: 'json',
			async: false,
			success: function success(data) {
				loading.stop();
				if (data.logged) {
					returnValue = true;
				}
			}, error: function () { 
				loading.stop();
			}
		});
		return returnValue;
	}


	$global('userDataReload',getUserData);
	return {
		init : init,
		user : getInfo,
		reload: getUserData,
		isLoad : function(){
			return isLoad===true;
		},
		onLoad: onLoad,
		isFrontUserLogged: isFrontUserLogged
	};
});

app.service('dispatcher', function(front, log, html, bottomBar, operator, partner, modal, eastMessage, tooltip, goBack,
	toCartView, oldToCartView, statusDelivery, sticky, cartBox, menu, headlessMenu, helpline, breadcrumb, lastSearchBox, holidayPromotion,
	livechat, modalService, energyLabel, cartManager, warrantyEncouragement, warrantyQuestionnaire, bannerEars, reportBug, reportNotify,
	reportFriend, reportAbuse, modalVideo, scrollTop, compareProducts, searchBox, productClipboard, footer,
	modalDictionary, shopsDetails, account, promotionBar, lazyLoad, productCompare, validate, cookie,
	wcAuthorizationView, partnerSubscriptionManager, productsListView, ajaxPage, watchedProducts,
	lastVisitedProducts, productOpinions, wcStockUpdate, wcBar, compareView, mainPageView, modalShops, callButton,
	nodeView, productVariants, modalDelivery, definitionsView, footerReveal, sharedCart, relatedPromotionCommercial,
	productSalesTabs, lowestPrice, availabilityNotification, message, sufficientOutletCategory,
	wcReportBug, brandView, loading, productCardTest, broker, captchaService, agreementsSubmit, dataSeoUrl, sessionStorage,
	emptySearchResultsView) {
	$(document).on('pageChange', function() {
		run();
	});
	function run() { // po wymianie strony bez przeładowania
		if (front.page() != 'to-cart' && front.group() != 'order' && front.group() != 'account' || front.group() != 'authorization') {
			goBack.setRefPage();
		}
		if (!$('#top.top2').length) {
			sessionStorage.removeItem("sdfghytu");
		}
		var $rotator = $('.js-rotator');
		$rotator.rotator();
		availabilityNotification.init();
		captchaService.init();
		$rotator.find('img').each(function () {
			var src = $(this).data('original');
			$(this).attr('src', src);
		});
		$('.select3-container:not(".lazy-init") input').filter('.select3-input').select3();
		$('.select-multi').selectMulti();
		if (front.page() !== 'leaf' && front.page() !== 'product-card' && front.page() !== 'product-detail') {
			log.visit();
		}
		checkErrors();
		switch (front.page()) {
			case 'to-cart':
				if (front.katalog) {
					oldToCartView.init();
				} else {
					toCartView.init();
				}
				break;
			case 'comparison':
				compareView.init();
				break;
			case 'node':
				nodeView.init();
				break;
			case 'brand':
				brandView.init();
				break;
			case 'main-page':
				mainPageView.init();
				break;
			case 'leaf':
				productsListView.init();
				break;
			case 'definitions':
				definitionsView.init();
				break;
			case 'wc-login':
				validate.task('wcLogin');
				break;
			case 'wc-order-preview':
				warrantyQuestionnaire.clearWarrantyQuestionnaire();
				break;
			case 'authorization-login':
			case 'authorization-password':
				wcAuthorizationView.init();
				break;
			case 'empty-search-results':
				emptySearchResultsView.init();
				break;
			default:

				break;
		}

		if ($('#watched-list #products').length) { //do późniejszej poprawy
			if ($('#watched-list #products').data('view') == 'WATCHED') {
				watchedProducts.init();
			} else {
				lastVisitedProducts.init();
			}
		}
		if ($('#opinion-table').length) { //w KK do udycji opinii
			productOpinions.init();
		}
		$('body').attr('wdio-random', parseInt(Math.random() * 100000000));
	}

	function pageRun() { // tylko raz na odświeżenie
		front.setPagesHistory();
		$('.select3-container:not(".lazy-init") input').filter('.select3-input').select3();
		cartBox.init();
		cartManager.init();
		account.init();		//account powinien być wysoko
		scrollTop.init();
		if (cookie.get('navTestAB') == 'B') {
			headlessMenu.init();
		} else {
			menu.init();
		}
		reportBug.init();
		wcReportBug.init();
		bottomBar.init();
		operator.init();
		partner.init();
		eastMessage.init();
		reportNotify.init();
		reportAbuse.init();
		reportFriend.init();
		lastSearchBox.init();
		livechat.init();
		helpline.init();
		breadcrumb.init();
		sharedCart.init();
		tooltip.init();

		holidayPromotion.init();
		energyLabel.init();
		statusDelivery.init();
		promotionBar.init();

		sticky.init(); //wcześniej powinno być notifyCookie i promotionBar

		bannerEars.init();
		lazyLoad.init();
		ajaxPage.init();
		productVariants.init();

		productCompare.init();
		searchBox.init();
		productClipboard.init();
		footer.init();
		modalDictionary.init();
		callButton.init();
		modalShops.init();
		modalDelivery.init();
		shopsDetails.init();
		modalVideo.init();
		footerReveal.init();
		productSalesTabs.init();
		modalService.init();
		lowestPrice.init();
		relatedPromotionCommercial.init();
		partnerSubscriptionManager.init();
		agreementsSubmit.init($('#agreements-form'), $("#current-permissions input:checkbox"));	// zgody w KK ale również /klient-akceptuje-zgody.bhtml
		agreementsSubmit.init($('#registerForm'), $("#registerForm input[name=permissionToProcessingAccepted]"));
		agreementsSubmit.init($('#order-delivery #cart form'), $("#current-permissions input:checkbox"));
		dataSeoUrl.init();

		if (front.ole || front.katalog) {
			sufficientOutletCategory.init();
		}

		$('body').on('click', '.js-terminal-enabled', function () {
			loading.start();
			message.get('product-card-payment-on-euro-delivery-message').then(function (html) {
				loading.stop();
				modal.generate({
					id: 'terminal-enabled-modal',
					content: html
				})
			})
		}).on('click', '.js-gnc-modal', function (e) {
			e.preventDefault();
			loading.start();
			message.get('modal-gnc-gwarancja-najnizszej-ceny').then(function (html) {
				loading.stop();
				modal.generate({
					id: 'gnc-modal',
					content: html
				})
			})
		}).on('click', '.js-show-warranties', function () {
			var warrentyAddedFrom = $(this).data('warranty-added-from') || false;
			var productPlu = $(this).data('product-plu') || ''
			warrantyEncouragement.load({
				redirectUrl: (front.katalog) ? '/koszyk.bhtml' : '/cart.bhtml'
			}, {
				productId: $(this).data('product'),
				packageIdentifier: $(this).data('package-identifier'),
				unpaidReservation: $(this).data('unpaid-reservation'),
				complaintExchange: $(this).data('complaint-exchange')
			}, function (params) {
				params.warrantyAddedFrom = warrentyAddedFrom;
				params.productPlu = productPlu;

				var warrantyToCartEventData = {
					id: 'to_cart',
					f03: front.page(),
					f04: params.warrantyId,
					f05: 'warranty',
					f06: productPlu,
					f07: 'warranty_box',
					f08: 'fancybox',
					f09: '',
					f10: params.warrantyPaymentType == 'POSTPONED' ? 'installment' : params.warrantyPaymentType == 'ONE_TIME' ? 'cash' : 'subscription' ,
				}
				if(warrantyToCartEventData.f03 == 'basket') {
					warrantyToCartEventData.f03 = 'cart_step_1';
				}
				log.event(warrantyToCartEventData);
				cartManager.addWarranty(params);
			}, function (params) {
				cartManager.removeWarranty(params);
			});
		}).on('mouseenter', '.js-photo-hover', function () {
			var $this = $(this);
			var data = $this.attr('data-hover');
			if (data) {
				var $img = $('<img class="hover" src="' + data + '"/>').on('load', function () {
					$(this).addClass('loaded');
				});
				$this.find('img').after($img);
				$this.removeAttr('data-hover');
			}
		}).on('click', '.js-price-voucher-info', function () {
			modal.open('price-voucher-modal-' + $(this).data('product'));
		}).on('click', '.js-price-installment-voucher-info', function () {
			modal.open('price-installment-voucher-modal-' + $(this).data('product'));
		}).on('click', '.js-price-installment-shop-info', function () {
			loading.start();
			message.get('instalments-with-advisor-only-modal').then(function (html) {
				loading.stop();
				modal.generate({
					id: 'price-installment-shop-modal',
					content: html
				});
			});
		}).on('click', 'a[href="#"]', function (event) {
			event.preventDefault();
		}).on('click', '.js-print', function (event) {
			UA.pageInteraction('Drukuj', 'Drukuj');
			log.recommendationsForAWS({
				productPlu: $('#product-top').data('plu') || $(this).data('plu'),
				eventCategory: 'Drukuj',
				eventAction: 'Drukuj'
			});
			window.print();
		}).on('click', '.product-market-price a, .product-competitor-price a', function (event) {
			$(this).closest('.product-market-price, .product-competitor-price').toggleClass('show-popup');
		}).on('click', '.js-add-to-compare', function () {
			compareProducts.click(parseInt($(this).data('product')), parseInt($(this).data('group')), parseInt($(this).data('plu')));
		}).on('click', '.checkbox-css .more, .checkbox-css .less', function (event) {
			$(this).closest('.text').toggleClass('long');
		}).on('click', '.js-shared-add-to-cart', function (e) {
			e.preventDefault();
			cartManager.addProduct({
				productId: $(this).data('product') || undefined,
				productPLU: $(this).data('plu') || undefined,
				services: $(this).data('services').split(':') || undefined,
				warranty: $(this).data('warranty') || undefined
			});
		}).on('click', '.js-add-to-cart', function () {
			if (productCardTest.enabled) {
				broker.trigger('ADD_TO_CART_WITHOUT_INSTALMENT', [{
					delivery: $(this).data('delivery') || undefined,
					addedFrom: $(this).data('added-from') || undefined,
					shopCode: $(this).data('shop') || undefined,
					relatedPromotionServiceId: $(this).data('related-promotion-service') || undefined,
				}]);
				return;
			}

			cartManager.addProduct({
				productId: $(this).data('product') || undefined,
				outletCategoryId: $(this).data('outlet-category') || undefined,
				outletProductId: $(this).data('outlet-product-id') || undefined,
				outletState: $(this).data('outlet-state') || undefined,
				relatedPromotionServiceId: $(this).data('related-promotion-service') || false,
				addedFrom: $(this).data('added-from') || undefined,
				pageSelector: $(this).data('page-selector') || undefined,
				button: $(this).data('button') || undefined,
				delivery: $(this).data('delivery') || undefined,
				productPLU: $(this).data('plu') || undefined,
				shopCode: $(this).data('shop') || undefined
			});
		}).on('click', '.js-add-to-cart-individual-offer', function () {
			cartManager.addProduct({
				productId: $(this).data('product') || undefined,
				outletCategoryId: $(this).data('outlet-category') || undefined,
				outletProductId: $(this).data('outlet-product-id') || undefined,
				addedFrom: $(this).data('added-from') || undefined,
				productPLU: $(this).data('plu') || undefined,
			});
		}).on('paste keyup change', 'textarea[maxlength]', function () {
			$('.char-counter-current', $(this).parent()).text($(this).val().length);
		}).on('keyup', 'input[name*="companyName"]', function () {			// gdy pierwsze pole firma osiągnie swój limit zostaje przeniesione do drugiego pola
			var $this = $(this);
			if ($(this).val().length == $(this).attr('maxlength')) {
				let name = $this.attr('name');
				let newName = false;
				if (name.indexOf('Part1')>-1) {
					newName = name.replace("Part1", "Part2")
				} else {
					newName = name.replace("companyName", "companyNameCont");
				}
				if (newName) {
					let $cont = $('input[name="' + newName + '"]');
					if ($cont.length) {
						console.log('osiągnięto limit w pierwszym polu firma, przeskok do pola: ' + newName);
						$cont.click().click().focus();
					}
				}
			}
		});

		if (front.katalog) {

			wcStockUpdate.init();
			$('body').on('click', '#second-menu .node-name a:not(".js-menu-close")', function (event) {
				ajaxPage.load({
					url: $(this).attr('href')
				});
				event.preventDefault();
			}).on('click', '.js-toggle-filter', function () {
				$('#products-filter').addClass('wc-show');
			}).on('click', function (event) {
				if (!$(event.target).closest('#products-filter').length) {
					$('#products-filter').removeClass('wc-show');
				}
			});
		}
		if (cookie.get('atrUser') == 'js') {
			html.add('atr-mode');
		}
		if (cookie.get('clean-mode') == 'true') {
			html.add('clean-mode');
		}
		run();
	}
	function checkErrors() {
		var velocityError = [];
		var freemarkerError = [];
		$('.velocity-error').each(function () {
			velocityError.push($(this).text().trim());
		});
		$('meta[name=velocity-error]').each(function () {
			velocityError.push($(this).attr('content').trim());
		});
		$('.freemarker-error').each(function () {
			freemarkerError.push($(this).text().trim());
		});
		$('meta[name=freemarker-error]').each(function () {
			freemarkerError.push($(this).attr('content').trim());
		});

		velocityError.filter((x, i, a) => a.indexOf(x) == i).forEach(element => {
			UA.pageview('/blad-velocity-error' + document.location.pathname);
			log.event({
				id: 'blad-velocity-error',
				f03: document.location.pathname,
				f04: element
			});
		});

		freemarkerError.filter((x, i, a) => a.indexOf(x) == i).forEach(element => {
			UA.pageview('/blad-freemarker-error' + document.location.pathname);
			log.event({
				id: 'blad-freemarker-error',
				f03: document.location.pathname,
				f04: element
			});
		});
	}
	return {
		run: run,
		pageRun: pageRun
	};
});
